var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c(
        "a",
        {
          staticClass: "btn kt-subheader__btn-daterange pr-1",
          attrs: {
            id: "course_attendance_period",
            title: "Course attendance period",
            href: "#",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.toggleMenuPopover.apply(null, arguments)
            },
          },
        },
        [
          _vm.currentItem
            ? _c("span", { staticClass: "kt-subheader__btn-daterange-date" }, [
                _vm._v(" " + _vm._s(_vm.currentItem.label) + " "),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "b-popover",
        {
          attrs: {
            triggers: "focus",
            target: "course_attendance_period",
            placement: "bottom",
            show: _vm.show,
            "custom-class": "menu-popover mt-4",
          },
          on: {
            "update:show": function ($event) {
              _vm.show = $event
            },
          },
        },
        [
          _vm.currentItem
            ? _c(
                "div",
                { staticClass: "kt-widget4" },
                _vm._l(_vm.items, function (item) {
                  return _c(
                    "div",
                    { key: `id_${item.id}`, staticClass: "kt-widget4__item" },
                    [
                      item.label == _vm.currentItem.label
                        ? _c("span", { staticClass: "kt-widget4__icon" }, [
                            _c("i", {
                              staticClass: "la la-check text-primary",
                            }),
                          ])
                        : _vm._e(),
                      _c(
                        "a",
                        {
                          staticClass:
                            "kt-widget4__title kt-widget4__title--light",
                          class: {
                            "not-selected":
                              item.label !== _vm.currentItem.label,
                          },
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.setPeriod(item)
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "kt-menu__link-text" }, [
                            _vm._v(" " + _vm._s(item.label) + " "),
                          ]),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]
      ),
      _c("b-modal", {
        ref: "course-attendance-date-modal",
        attrs: {
          id: "course-attendance-date-modal",
          size: "lg",
          variant: "primary",
          centered: "",
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function () {
              return [
                _c("h4", { staticClass: "modal-title" }, [
                  _vm._v(" Custom Date Range "),
                ]),
                _c("button", {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.closeModal()
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function () {
              return [
                _c("div", { staticClass: "modal-body" }, [
                  _c(
                    "div",
                    { staticClass: "row w-100 d-flex justify-content-around" },
                    [
                      _c(
                        "div",
                        [
                          _c("div", { staticClass: "w-100" }, [
                            _c("h5", [_vm._v("Start Date")]),
                          ]),
                          _c("b-calendar", {
                            staticClass: "date-selector pt-3 pb-4",
                            attrs: {
                              id: "start-datepicker",
                              min: _vm.minStartDate,
                              max: _vm.maxStartDate,
                            },
                            model: {
                              value: _vm.modal.startDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.modal, "startDate", $$v)
                              },
                              expression: "modal.startDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("div", { staticClass: "w-100" }, [
                            _c("h5", [_vm._v("End Date")]),
                          ]),
                          _c("b-calendar", {
                            staticClass: "date-selector pt-3",
                            attrs: {
                              id: "end-datepicker",
                              min: _vm.minEndDate,
                            },
                            model: {
                              value: _vm.modal.endDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.modal, "endDate", $$v)
                              },
                              expression: "modal.endDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "modal-footer",
            fn: function () {
              return [
                _c("div", { staticClass: "row w-100" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.closeModal()
                          },
                        },
                      },
                      [_vm._v(" Close ")]
                    ),
                  ]),
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary pull-right",
                        attrs: { type: "button", disabled: _vm.isDisabled },
                        on: {
                          click: function ($event) {
                            return _vm.setCustomPeriod()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-save" }),
                        _vm._v(" Apply period "),
                      ]
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }