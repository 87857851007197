var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("CourseSubHeader", {
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function () {
              return [
                _c("PeriodDatePicker"),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.bottomleft",
                        modifiers: { hover: true, bottomleft: true },
                      },
                    ],
                    staticClass: "btn kt-subheader__btn-primary btn-icon",
                    attrs: {
                      title: _vm.touchMode
                        ? "Keyboard/Mouse Mode"
                        : "Touch Mode",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        _vm.touchMode = !_vm.touchMode
                      },
                    },
                  },
                  [
                    _vm.touchMode
                      ? _c("i", { staticClass: "fa fa-mouse" })
                      : _c("i", { staticClass: "fa fa-bullseye" }),
                  ]
                ),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.bottomleft",
                        modifiers: { hover: true, bottomleft: true },
                      },
                    ],
                    staticClass: "btn kt-subheader__btn-primary btn-icon",
                    attrs: { title: "Bulk Attendance" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.toggleModalBulkAttendance.apply(
                          null,
                          arguments
                        )
                      },
                    },
                  },
                  [
                    _vm.bulkAttendanceVisible
                      ? _c("i", { staticClass: "flaticon2-accept" })
                      : _c("i", { staticClass: "flaticon2-check-mark" }),
                  ]
                ),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.bottomleft",
                        modifiers: { hover: true, bottomleft: true },
                      },
                    ],
                    staticClass: "btn kt-subheader__btn-primary btn-icon",
                    attrs: {
                      href: "#",
                      title: _vm.onlyCourseMeetings
                        ? "Show Non School Days"
                        : "Hide Non School Days",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        _vm.onlyCourseMeetings = !_vm.onlyCourseMeetings
                      },
                    },
                  },
                  [
                    _vm.onlyCourseMeetings
                      ? _c("i", { staticClass: "flaticon2-calendar" })
                      : _c("i", { staticClass: "flaticon2-calendar-2" }),
                  ]
                ),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.bottomleft",
                        modifiers: { hover: true, bottomleft: true },
                      },
                    ],
                    staticClass: "btn kt-subheader__btn-primary btn-icon",
                    attrs: { href: "#", title: "Export To CSV" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.exportData.apply(null, arguments)
                      },
                    },
                  },
                  [_c("i", { staticClass: "flaticon-download-1" })]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass:
            "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pr-2 pl-4",
        },
        [
          _c("div", { staticClass: "kt-portlet p-0 m-0" }, [
            _c(
              "div",
              { staticClass: "kt-portlet__body p-0 m-0" },
              [
                _vm.isPortfolioReady && _vm.dataSource.data.length > 0
                  ? _c("CourseAttendanceHotTable", {
                      key: `key_${_vm.key}`,
                      ref: "wrapperCourse",
                      attrs: {
                        course: _vm.course,
                        students: _vm.students,
                        columns: _vm.days,
                        "data-source": _vm.dataSource.data,
                        cell: _vm.dataSource.cellMetadata,
                        "set-bulk-date": _vm.setBulkDate,
                        "set-attendance-for-date": _vm.setAttendanceForDate,
                        "touch-mode": _vm.touchMode,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("BulkAttendance", {
        attrs: {
          course: _vm.course,
          students: _vm.students,
          "attendance-for-date": _vm.attendanceForDate,
          "bulk-date": _vm.bulkDate,
          "bulk-attendance-visible": _vm.bulkAttendanceVisible,
          "close-bulk-attendance": _vm.closeModalBulkAttendance,
          "increment-key": _vm.incrementKey,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }