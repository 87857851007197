<template>
<div
    v-if="dataSource.length > 0"
    :key="`key_${key}`"
    class="w-100 attendance-hot-table-container"
>
    <hot-table
        :key="`table_${key}`"
        ref="wrapper"
        :settings="hotSettings"
    >
        <hot-column
            title="Student"
            :width="180"
            :read-only="true"
            :settings="{
                data: `student`,
            }"
        >
            <HotCellStudentDetails hot-renderer />
        </hot-column>
        <hot-column
            title="YTD"
            :width="48"
            :read-only="true"
            :settings="{
                data: `student`,
            }"
        >
            <HotCellAttendancePercentage hot-renderer />
        </hot-column>
        <hot-column
            v-for="day in columns"
            :key="`att.${day}`"
            :width="48"
            :read-only="!canEditCourse"
            :settings="{
                data: `${day}`,
                type: 'dropdown',
                title: getColumnTitle(day),
                source: ['', 'Present', 'Absent', 'Late', 'Excused'],
                validator: 'sg.attendance',
                allowInvalid: false,
            }"
        >
            <InlineHotCell hot-renderer />
        </hot-column>
    </hot-table>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import Vue from 'vue';
import moment from 'moment';
import HotCellStudentDetails from './HotCellStudentDetails.vue';
import { hotDefaults } from '../store/hotDefaults';
import HotCellAttendancePercentage from './HotCellAttendancePercentage.vue';
import Types from '../store/Types';
import * as network from '../network';

// vue native components not 100% supported
// as per https://github.com/handsontable/handsontable/issues/7535
const InlineHotCell = {
    name: 'InlineHotCell',
    template: `<span class="att-cell" :class="cellClassNames">
        <span :class="['btn btn-icon btn-sm btn-upper btn-bold att-text', attendanceColor]">
            {{ attendanceShortValue }}
        </span>
    </span>`,
    data() {
        return {
            hotInstance: null,
            TD: null,
            row: null,
            col: null,
            day: null,
            value: null,
            cellProperties: null,
        };
    },
    computed: {
        attendanceState() {
            return this.value;
        },
        attendanceShortValue() {
            const { attendanceState } = this;
            if (!attendanceState) return '';
            const firstCharacter = (attendanceState || '').toUpperCase().substr(0, 1);
            if (!['A', 'P', 'L', 'E'].includes(firstCharacter)) return '-';
            return firstCharacter;
        },
        attendanceColor() {
            const { attendanceShortValue } = this;

            if (!attendanceShortValue) return '';
            const colors = {
                A: 'Red',
                P: 'Green',
                L: 'Yellow',
                E: 'Blue',
            };
            return colors[attendanceShortValue] || '';
        },
        cellClassNames() {
            const classNames = [];
            if (!this.cellProperties) return classNames;
            const { isMeetingThisDay, isFirstMeetingDayOfWeek, isThisDayToday } = this.cellProperties;
            if (isThisDayToday) classNames.push('today-column');
            if (isFirstMeetingDayOfWeek) classNames.push('first-meeting-of-week');
            if (!isMeetingThisDay) classNames.push('course-not-meeting');
            return classNames;
        },
    },
};

export default Vue.extend({
    name: 'CourseAttendanceHotTable',
    components: {
        HotCellStudentDetails,
        InlineHotCell,
        HotCellAttendancePercentage,
    },
    mixins: [],
    props: {
        course: {
            type: Object,
            required: true,
        },
        students: {
            type: Array,
            required: true,
        },
        columns: {
            type: Array,
            required: true,
        },
        dataSource: {
            type: Array,
            required: true,
        },
        cell: {
            type: Array,
            required: true,
        },
        setBulkDate: {
            type: Function,
            required: true,
        },
        setAttendanceForDate: {
            type: Function,
            required: true,
        },
        touchMode: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        const data = {
            key: 1,
            firstColumnWidth: 180,
            presentStatus: ['Present', 'Excused', 'Late'],
            newAttendance: [],
            cellSource: ['', 'Present', 'Absent', 'Late', 'Excused'],
            hotSettings: {
                data: [],
                cell: [],
                autoColumnSize: false,
                autoRowSize: false,
                columnHeaderHeight: 50,
                comments: true,
                fixedColumnsStart: 1,
                rowHeaders: true,
                rowHeaderWidth: 50,
                rowHeights: 50,
                width: '100%',
                columnSorting: {
                    compareFunctionFactory(sortOrder) {
                        return function (currentValue, nextValue) {
                            if (!currentValue) {
                                return 1;
                            }
                            if (!nextValue) {
                                return -1;
                            }

                            const returnByOrder = sortOrder == 'asc' ? -1 : 1;
                            if (currentValue < nextValue) {
                                return returnByOrder * -1;
                            }
                            if (currentValue > nextValue) {
                                return returnByOrder;
                            }
                            return 0;
                        };
                    },
                },
                contextMenu: {
                    items: {
                        commentsAddEdit: {},
                        commentsRemove: {},
                    },
                },
            },
        };

        data.hotSettings = {
            ...hotDefaults,
            ...data.hotSettings,
        };

        return data;
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        canEditCourse() {
            return this.course.canEditCourse || false;
        },
        courseMeetings() {
            if (!this.course) return [];
            return this.course.courseMeetings || [];
        },
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
        courseSectionAttendance() {
            return this.$store.state.database.courseSectionAttendance;
        },
    },
    watch: {
    },
    mounted() {
        this.init();
    },
    beforeDestroy() {
        if (this.newAttendance.length > 0) {
            this.$store.commit(Types.mutations.UPSERT_COURSE_SECTION_ATTENDANCE, this.newAttendance);
        }
    },
    methods: {
        getColumnTitle(date) {
            const d = moment(date, 'YYYY-MM-DD');
            if (date == moment().format('YYYY-MM-DD')) {
                return `<strong>${d.format('ddd')}<br>${d.format('M-DD')}</strong>`;
            }
            return `${d.format('ddd')}<br>${d.format('M-DD')}`;
        },
        init() {
            const {
                dataSource, cell, cellSource,
            } = this;
            const {
                saveChanges, saveComment, getDataSource, getTouchMode,
            } = this;

            this.hotSettings.afterGetRowHeader = function (idx, ele) {
                // vert center row #
                const div = ele.firstChild;
                div.className = 'mt-3';

                const row = dataSource[idx];
                const { student } = row;
                if (student && student.courseSection) {
                    const { courseSectionHexColor } = student.courseSection;
                    if (courseSectionHexColor) {
                        // https://stackoverflow.com/a/66143374
                        const setOpacity = (hex, alpha) => `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, '0')}`;
                        div.parentNode.style = `background-color: ${setOpacity(courseSectionHexColor, 0.2)}`;
                    }
                }
            };

            this.hotSettings.afterSetCellMeta = (row, col, key, val) => {
                if (key == 'comment') saveComment(row, col, val);
            };

            this.hotSettings.afterChange = function (changes) {
                if (!changes) return;
                changes.forEach(([rowIndex, day, oldValue, newValue]) => {
                    saveChanges(rowIndex, day, oldValue, newValue);
                });
            };

            this.hotSettings.beforeOnCellContextMenu = (event, cords) => {
                if (cords.col < 2 || getTouchMode()) {
                    event.stopImmediatePropagation();
                }
            };

            this.hotSettings.afterSelectionEnd = (row, col, row2, col2, layer) => {
                if (col > 1) {
                    const date = `${this.columns[col - 2]}`;
                    this.setBulkDate(date);
                }
            };

            this.hotSettings.beforeOnCellMouseDown = (event, coords, ele, controller) => {
                const { row, col } = coords;
                if (row == -1 || col <= 1) return;
                if (!getTouchMode()) return;
                event.stopImmediatePropagation();
                const date = this.columns[col - 2];
                const oldValue = getDataSource()[row][date] || '';
                const position = cellSource.indexOf(oldValue);
                const newValue = position == cellSource.length - 1 ? cellSource[0] : cellSource[position + 1];
                saveChanges(row, date, oldValue, newValue);
            };

            this.hotSettings.height = document.documentElement.clientHeight - 160;
            this.hotSettings.data = dataSource;
            this.hotSettings.cell = cell;

            this.key += 1; // force refresh
        },
        saveChanges(rowIndex, attendanceDate, oldAttendanceState, attendanceState) {
            // No change
            if (!oldAttendanceState && attendanceState === '') return;

            // find the student by rowIndex
            const v = this;
            const { showError, touchMode } = this;
            const row = this.hotSettings.data[rowIndex];
            const { student } = row;

            const saveError = `There was a network error saving attendance for ${student.name} on ${attendanceDate}. Reload the page and try again.`;

            const courseSectionAttendance = row[`${attendanceDate}-object`];
            const { courseSectionId, courseSectionStudentId } = courseSectionAttendance;
            const { studentEnrollmentId, attendanceComment } = courseSectionAttendance;

            if (oldAttendanceState && oldAttendanceState !== '' && attendanceState === '') attendanceState = null;

            const { schoolId, schoolTermId } = this.user.school;

            const attendance = {
                courseSectionId,
                courseSectionStudentId,
                attendanceDate,
                attendanceState,
                attendanceComment,

                deleted: !attendanceState,
            };
            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    courseSectionId,
                },
                body: {
                    courseSectionAttendance: [attendance],
                },
            };

            network.courseSectionAttendance.saveCourseAttendance(params, (err) => {
                if (err) {
                    console.error(err);
                    return showError(saveError);
                }

                if (touchMode) {
                    v.$store.commit(Types.mutations.UPSERT_COURSE_SECTION_ATTENDANCE, [{
                        studentEnrollmentId,
                        ...attendance,
                    }]);
                } else {
                    this.newAttendance.push({
                        studentEnrollmentId,
                        ...attendance,
                    });
                }

                this.setAttendanceForDate(JSON.parse(JSON.stringify(this.newAttendance)));
            });
        },
        saveComment(row, col, val) {
            const { showError } = this;
            const date = `${this.columns[col - 2]}-object`;
            const attendance = this.hotSettings.data[row][date];
            const attendanceState = this.hotSettings.data[row][this.columns[col - 2]] || null;

            if (!attendance || (val && (!val.value || val.value == attendance.attendanceComment))) return;

            const { courseSectionId, courseSectionStudentId } = attendance;
            const { studentEnrollmentId, attendanceDate } = attendance;
            const { schoolId, schoolTermId } = this.user.school;

            const newAttendance = {
                courseSectionId,
                courseSectionStudentId,
                attendanceDate,
                attendanceState,
                attendanceComment: val ? val.value : '',
                deleted: attendance.deleted,
            };
            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    courseSectionId,
                },
                body: {
                    courseSectionAttendance: [newAttendance],
                },
            };

            network.courseSectionAttendance.saveCourseAttendance(params, (err) => {
                if (err) {
                    console.error(err);
                    return showError(err);
                }
                this.newAttendance.push({
                    studentEnrollmentId,
                    ...newAttendance,
                });
            });
        },
        getDataSource() {
            return this.dataSource;
        },
        getTouchMode() {
            return this.touchMode;
        },
    },
});

</script>

<style scoped src="../css/colors.scss" lang="scss" />

<style>
div.attendance-hot-table-container span.att-cell {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

div.attendance-hot-table-container span.att-text {
    font-size: 1.2rem;
    line-height: 0rem;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

div.attendance-hot-table-container span.first-meeting-of-week {
    border-left: 1px solid rgba(10, 102, 183, 0.5);
}

div.attendance-hot-table-container span.today-column {
    background-color: rgba(51, 154, 242, 0.2) !important;
}

div.attendance-hot-table-container span.course-not-meeting {
    background-color: rgb(201 198 198 / 0.1);
}

div.attendance-hot-table-container .handsontable table.htCore thead tr th,
div.attendance-hot-table-container .handsontable table.htCore thead tr th .colHeader,
div.attendance-hot-table-container .ht_master table.htCore tbody tr td {
    vertical-align: middle;
    text-align: center;
}

div.attendance-hot-table-container .handsontable {
    z-index: 1;
}
div.attendance-hot-table-container .ht_master table.htCore tbody tr td .htItemWrapper {
    text-align: left;
}

div.attendance-hot-table-container .htContextMenu .ht_master table.htCore {
    width: 120px;
    display: block;
}

div.attendance-hot-table-container .ht_master table.htCore tbody tr td {
    height: 47px;
    padding: 0;
}

div.attendance-hot-table-container .handsontable span.colHeader.columnSorting::before {
    right: -4px;
}

div.attendance-hot-table-container .handsontable.ht_clone_top_inline_start_corner span.colHeader.columnSorting::before {
    right: -14px;
}

</style>
