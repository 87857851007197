<template>
<div>
    <CourseSubHeader>
        <template #buttons>
            <PeriodDatePicker />

            <a
                v-b-tooltip.hover.bottomleft
                class="btn kt-subheader__btn-primary btn-icon"
                :title="touchMode ? 'Keyboard/Mouse Mode' : 'Touch Mode'"
                @click.stop.prevent="touchMode = !touchMode"
            >
                <i
                    v-if="touchMode"
                    class="fa fa-mouse"
                />
                <i
                    v-else
                    class="fa fa-bullseye"
                />
            </a>

            <a
                v-b-tooltip.hover.bottomleft
                class="btn kt-subheader__btn-primary btn-icon"
                title="Bulk Attendance"
                @click.stop.prevent="toggleModalBulkAttendance"
            >
                <i
                    v-if="bulkAttendanceVisible"
                    class="flaticon2-accept"
                />
                <i
                    v-else
                    class="flaticon2-check-mark"
                />
            </a>

            <a
                v-b-tooltip.hover.bottomleft
                href="#"
                :title="onlyCourseMeetings ? 'Show Non School Days' : 'Hide Non School Days'"
                class="btn kt-subheader__btn-primary btn-icon"
                @click.stop.prevent="onlyCourseMeetings = !onlyCourseMeetings"
            >
                <i
                    v-if="onlyCourseMeetings"
                    class="flaticon2-calendar"
                />
                <i
                    v-else
                    class="flaticon2-calendar-2"
                />
            </a>

            <a
                v-b-tooltip.hover.bottomleft
                href="#"
                title="Export To CSV"
                class="btn kt-subheader__btn-primary btn-icon"
                @click.stop.prevent="exportData"
            >
                <i class="flaticon-download-1" />
            </a>
        </template>
    </CourseSubHeader>
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pr-2 pl-4">
        <div class="kt-portlet p-0 m-0">
            <div
                class="kt-portlet__body p-0 m-0"
            >
                <CourseAttendanceHotTable
                    v-if="isPortfolioReady && dataSource.data.length > 0"
                    ref="wrapperCourse"
                    :key="`key_${key}`"
                    :course="course"
                    :students="students"
                    :columns="days"
                    :data-source="dataSource.data"
                    :cell="dataSource.cellMetadata"
                    :set-bulk-date="setBulkDate"
                    :set-attendance-for-date="setAttendanceForDate"
                    :touch-mode="touchMode"
                />
            </div>
        </div>
    </div>

    <BulkAttendance
        :course="course"
        :students="students"
        :attendance-for-date="attendanceForDate"
        :bulk-date="bulkDate"
        :bulk-attendance-visible="bulkAttendanceVisible"
        :close-bulk-attendance="closeModalBulkAttendance"
        :increment-key="incrementKey"
    />
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import moment from 'moment';
import courseMixins from '../store/mixins/courseMixins';
import portfolioMixins from '../store/mixins/portfolioMixins';
import studentMixins from '../store/mixins/studentMixins';
import models from '../models';
import CourseSubHeader from './CourseSubHeader.vue';
import PeriodDatePicker from '../components/CourseAttendance/PeriodDatePicker.vue';
import CourseAttendanceHotTable from '../components/CourseAttendanceHotTable.vue';
import BulkAttendance from '../components/BulkAttendance.vue';
import Types from '../store/Types';

export default Vue.extend({
    name: 'CourseAttendance',
    components: {
        CourseSubHeader,
        CourseAttendanceHotTable,
        PeriodDatePicker,
        BulkAttendance,
    },
    mixins: [portfolioMixins, courseMixins, studentMixins],
    data() {
        return {
            key: 0,
            bulkAttendanceVisible: false,
            bulkDate: null,
            attendanceForDate: [],
            touchMode: false,
        };
    },
    computed: {
        isPortfolioReady() {
            const cacheKey = this.$_portfolioMixins_getPortfolioCacheKey;
            return Boolean(this.$store.state.database.cache.find((c) => c.key == cacheKey && c.status == 'cached'))
                && this.endDate;
        },
        startDate: {
            get() { return this.$store.state.database.attendance.startDate; },
            set(startDate) {
                this.$store.commit(Types.mutations.SET_ATTENDANCE_START_DATE, startDate);
            },
        },
        endDate: {
            get() { return this.$store.state.database.attendance.endDate; },
            set(endDate) {
                this.$store.commit(Types.mutations.SET_ATTENDANCE_END_DATE, endDate);
            },
        },
        label: {
            get() { return this.$store.state.database.attendance.label; },
            set(label) {
                this.$store.commit(Types.mutations.SET_ATTENDANCE_LABEL, label);
            },
        },
        onlyCourseMeetings: {
            get() { return this.$store.state.database.attendance.onlyCourseMeetings; },
            set(onlyCourseMeetings) {
                this.$store.commit(Types.mutations.SET_ATTENDANCE_ONLY_COURSE_MEETINGS, onlyCourseMeetings);
            },
        },
        course() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        courseMeetings() {
            if (!this.course) return [];
            return this.course.courseMeetings || [];
        },
        students() {
            if (!this.course || !this.isPortfolioReady) return [];
            const students = this.$_studentMixins_getStudentsForCourse(this.course);
            return students;
        },
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
        courseSectionAttendance() {
            return this.$store.state.database.courseSectionAttendance;
        },
        title() {
            const route = this.$route;
            return route.meta.title || null;
        },
        icon() {
            const route = this.$route;
            return route.meta.icon || null;
        },
        days() {
            const days = [];

            const startDate = moment(this.startDate, 'YYYY-MM-DD');
            const endDate = moment(this.endDate, 'YYYY-MM-DD');
            const diff = endDate.diff(startDate, 'days') + 1;

            let index = 0;
            for (index = 0; index < diff; index += 1) {
                const nextDate = moment(startDate).add(index, 'days');
                const dayFound = this.courseMeetings.find((cm) => cm.day == nextDate.format('dddd'));

                if (!this.onlyCourseMeetings || (this.onlyCourseMeetings && dayFound)) {
                    days.push(
                        nextDate.format('YYYY-MM-DD'),
                    );
                }
            }
            return days;
        },
        dataSource() {
            const { students, days, course } = this;
            const { getCourseSectionAttendanceForCourse } = this;
            const data = [];
            const cellMetadata = [];
            if (!course) return { data, cellMetadata };

            const attendanceRecords = getCourseSectionAttendanceForCourse(course);
            // for each student in group courses
            students.forEach((student, rowIndex) => {
                const { courseSection, studentEnrollmentId, courseSectionStudentId } = student;
                const { courseSectionId } = courseSection;
                const studentAttendanceRecords = attendanceRecords.filter((attendance) => attendance.courseSectionStudentId == courseSectionStudentId);

                // loop each attendance date and save to the data source
                const record = {
                    student: {
                        courseSection,
                        courseSectionId,
                        courseSectionStudentId,
                        student,
                        studentEnrollmentId,
                        name: `${student.lastName}, ${student.firstName}`,
                    },
                };

                const today = moment().format('YYYY-MM-DD');
                days.forEach((day, cellIndex) => {
                    const blankRecord = {
                        courseSection,
                        courseSectionStudentId,
                        courseSectionId,
                        attendanceDate: day,
                        attendanceState: null,
                        attendanceComment: null,
                        deleted: false,
                    };
                    const existingRecord = studentAttendanceRecords.find((a) => a.attendanceDate == day) || null;

                    // ensure each cell has the same default state
                    const courseSectionAttendance = new models.CourseSectionAttendance(existingRecord || blankRecord);
                    record[`${day}-object`] = courseSectionAttendance;
                    record[`${day}`] = courseSectionAttendance.attendanceState;
                    const isThisDayToday = Boolean(today == day);
                    const [firstMeeting] = this.courseMeetings;
                    const weekDay = moment(day, 'YYYY-MM-DD').format('dddd');
                    const isFirstMeetingDayOfWeek = firstMeeting && Boolean(firstMeeting.day == weekDay);

                    const isMeetingThisDay = Boolean(this.courseMeetings.find((cm) => cm.day == weekDay));

                    cellMetadata.push({
                        row: rowIndex,
                        col: cellIndex + 2, // (col0 = name, col1 = att% 3... = attendance
                        comment: { value: courseSectionAttendance.attendanceComment },
                        courseMeeting: isMeetingThisDay,
                        isThisDayToday,
                        isMeetingThisDay,
                        isFirstMeetingDayOfWeek,
                    });
                });
                data.push(record);
            });
            return {
                data,
                cellMetadata,
            };
        },
    },
    watch: {
        course() {
            this.key += 1; // force refresh
        },
        extCourseSectionId() {
            this.key += 1; // force refresh
        },
        onlyCourseMeetings() {
            this.key += 1; // force refresh
        },
        startDate() {
            this.key += 1; // force refresh
        },
        endDate() {
            this.key += 1; // force refresh
        },
        dataSource() {
            this.setAttendanceForDate();
            // this.key += 1; // force refresh
        },
    },
    mounted() {
        const { courseMeetings } = this;
        if (!courseMeetings.length) this.onlyCourseMeetings = false;
        else this.onlyCourseMeetings = true;

        setTimeout(() => {
            // waiting because the dataSource is slow to fill
            this.loadModalBulkAttendance();
            this.setBulkDate(moment(new Date()).format('YYYY-MM-DD'));
        }, 400);
    },
    methods: {
        incrementKey() {
            this.key += 1;
        },
        getCourseSectionAttendanceForCourse(course) {
            const { courseSectionAttendance } = this.$store.state.database;
            const { courseSectionIds } = course;
            return courseSectionAttendance.filter((attendance) => courseSectionIds.includes(attendance.courseSectionId) && !attendance.deleted);
        },
        exportData() {
            const {
                startDate, endDate, days, course,
            } = this;
            const columns = [{ key: 'student', title: 'student' }, { key: 'YTD', title: 'YTD' }, ...days.map((day) => ({ key: day, title: this.getColumnTitle(day) }))];
            const { data } = this.$refs.wrapperCourse.$refs.wrapper._props.settings;
            const labelsData = columns.map((column) => column.key);

            let csv = columns
                .map((header) => header.title.replaceAll('<br>', ' ').replaceAll('<strong>', ''))
                .join(',');
            csv += '\n';
            data.forEach((row) => {
                csv += labelsData
                    .map((cell) => {
                        if (cell == 'YTD') {
                            const totalPossible = Object.values(row).filter((a) => a && a.attendanceState).length || 0;
                            const totalPresent = Object.values(row).filter((a) => a && a.attendanceState && a.attendanceState != 'Absent').length || 0;
                            return `=\"${totalPresent}/${totalPossible}\"`;
                        }
                        if (row[cell]) {
                            if (row[cell].name) return `\"${row[cell].name}\"`;
                            return row[cell];
                        }
                        return '';
                    })
                    .join(',');
                csv += '\n';
            });

            const filename = `${course.name}_Attendance_${startDate}-${endDate}`.replaceAll(' ', '_');
            const anchor = document.createElement('a');
            anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
            anchor.target = '_blank';
            anchor.download = `${filename}.csv`;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        },
        getColumnTitle(date) {
            const d = moment(date, 'YYYY-MM-DD');
            if (date == moment().format('YYYY-MM-DD')) {
                return `<strong>${d.format('ddd')}<br>${d.format('M-DD')}</strong>`;
            }
            return `${d.format('ddd')}<br>${d.format('M-DD')}`;
        },
        toggleModalBulkAttendance() {
            this.bulkAttendanceVisible = !this.bulkAttendanceVisible;
        },
        closeModalBulkAttendance() {
            this.bulkAttendanceVisible = false;
        },
        loadModalBulkAttendance() {
            const dateString = moment(new Date()).format('YYYY-MM-DD').toString();
            const { data } = this.dataSource;
            const isThereAnyAttendance = data.some((attendance) => attendance[dateString]);
            if (!isThereAnyAttendance) {
                this.bulkAttendanceVisible = true;
            }
        },
        setBulkDate(date) {
            this.bulkDate = date;
            this.setAttendanceForDate();
        },
        setAttendanceForDate(overrides) {
            const { bulkDate } = this;
            if (!bulkDate) {
                this.attendanceForDate = [];
                return;
            }

            const { data } = this.dataSource;
            if (!data) {
                this.attendanceForDate = [];
                return;
            }

            this.attendanceForDate = data.map((a) => {
                const { student } = a;
                const { courseSectionStudentId, studentEnrollmentId } = student;
                const dateAttendance = a[bulkDate] || null;
                if (!overrides || !overrides.length) return { attendanceState: dateAttendance, courseSectionStudentId, studentEnrollmentId };

                const override = overrides.find((o) => {
                    if (!dateAttendance) return false;
                    return o.courseSectionStudentId == courseSectionStudentId && o.attendanceDate == bulkDate;
                }) || null;
                return override || { attendanceState: dateAttendance, courseSectionStudentId, studentEnrollmentId };
            }).filter((a) => a);
        },
    },
});
</script>
