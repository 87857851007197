var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: `key_${_vm.key}_${_vm.courseSectionStudentId}_${_vm.courseSectionId}`,
    },
    [
      _c("div", { class: ["kt-font-boldest", _vm.color] }, [
        _vm._v(" " + _vm._s(_vm.formattedPercentage) + " "),
      ]),
      _vm.totalPossible
        ? _c("small", { staticClass: "text-muted text-nowrap text-truncate" }, [
            _vm._v(
              " " +
                _vm._s(_vm.totalPresent) +
                "/" +
                _vm._s(_vm.totalPossible) +
                " "
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }