var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.dataSource.length > 0
    ? _c(
        "div",
        {
          key: `key_${_vm.key}`,
          staticClass: "w-100 attendance-hot-table-container",
        },
        [
          _c(
            "hot-table",
            {
              key: `table_${_vm.key}`,
              ref: "wrapper",
              attrs: { settings: _vm.hotSettings },
            },
            [
              _c(
                "hot-column",
                {
                  attrs: {
                    title: "Student",
                    width: 180,
                    "read-only": true,
                    settings: {
                      data: `student`,
                    },
                  },
                },
                [
                  _c("HotCellStudentDetails", {
                    attrs: { "hot-renderer": "" },
                  }),
                ],
                1
              ),
              _c(
                "hot-column",
                {
                  attrs: {
                    title: "YTD",
                    width: 48,
                    "read-only": true,
                    settings: {
                      data: `student`,
                    },
                  },
                },
                [
                  _c("HotCellAttendancePercentage", {
                    attrs: { "hot-renderer": "" },
                  }),
                ],
                1
              ),
              _vm._l(_vm.columns, function (day) {
                return _c(
                  "hot-column",
                  {
                    key: `att.${day}`,
                    attrs: {
                      width: 48,
                      "read-only": !_vm.canEditCourse,
                      settings: {
                        data: `${day}`,
                        type: "dropdown",
                        title: _vm.getColumnTitle(day),
                        source: ["", "Present", "Absent", "Late", "Excused"],
                        validator: "sg.attendance",
                        allowInvalid: false,
                      },
                    },
                  },
                  [_c("InlineHotCell", { attrs: { "hot-renderer": "" } })],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }