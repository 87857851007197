<template>
<div :key="`key_${key}_${courseSectionStudentId}_${courseSectionId}`">
    <div :class="['kt-font-boldest', color]">
        {{ formattedPercentage }}
    </div>
    <small
        v-if="totalPossible"
        class="text-muted text-nowrap text-truncate"
    > {{ totalPresent }}/{{ totalPossible }} </small>
</div>
</template>

<script lang="ts">
export default {
    name: 'HotCellAttendancePercentage',
    data() {
        return {

            hotInstance: null,
            TD: null,
            row: null,
            col: null,
            prop: null,
            value: null,
            cellProperties: null,
            key: 0,
        };
    },
    computed: {
        courseSectionStudentId() {
            return this.value ? this.value.courseSectionStudentId : null;
        },
        courseSectionId() {
            return this.value ? this.value.courseSectionId : null;
        },
        courseSectionAttendance() {
            const { courseSectionStudentId, courseSectionId } = this;
            if (!courseSectionStudentId || !courseSectionId) return [];
            return this.$store.state.database.courseSectionAttendance.filter((r) => r.courseSectionStudentId == courseSectionStudentId && r.courseSectionId == courseSectionId);
        },
        totalPresent() {
            return this.courseSectionAttendance.filter((r) => r.attendanceState != 'Absent').length;
        },
        totalPossible() {
            return this.courseSectionAttendance.length;
        },
        percentage() {
            const { totalPossible, totalPresent } = this;
            if (totalPossible == 0) return 0;
            return Math.round((totalPresent / totalPossible) * 100);
        },
        color() {
            const { percentage } = this;
            if (percentage === null) return 'kt-font-dark';
            if (percentage > 99) return 'kt-font-primary';
            if (percentage > 98) return 'kt-font-success';
            return 'kt-font-danger';
        },
        formattedPercentage() {
            const { percentage } = this;
            return percentage !== null ? `${percentage}%` : '-';
        },
    },
    watch: {
        courseSectionAttendance() {
            this.key += 1;
        },
    },
};
</script>
