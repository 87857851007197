<template>
<div
    v-if="bulkAttendanceVisible"
    class="modal modal-sticky-bottom-right modal-sticky-lg show"
    role="dialog"
    data-backdrop="false"
    aria-modal="true"
    style="display: block; max-width: 650px;"
>
    <div class="modal-dialog" style="max-width: 650px;">
        <div class="modal-content kt-inbox">
            <div class="kt-portlet">
                <div class="kt-portlet__head">
                    <div class="kt-portlet__head-label">
                        <span class="kt-portlet__head-icon kt-hidden">
                            <i class="la la-gear" />
                        </span>
                        <h3 class="kt-portlet__head-title">
                            Bulk Attendance
                        </h3>
                    </div>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-actions">
                            <span class="mr-4">
                                <span class="text-muted mr-4">
                                    Fill Mode:
                                </span>
                                <div class="btn-group">
                                    <button
                                        v-b-tooltip.hover.bottom="'Fill for all students for date'"
                                        type="button"
                                        class="btn btn-bold btn-sm"
                                        :class="{
                                            'btn-primary': fillMode === 'all',
                                            'btn-clean': fillMode !== 'all'
                                        }"
                                        @click.stop.prevent="setFillMode('all')"
                                    >
                                        All
                                    </button>
                                    <button
                                        v-b-tooltip.hover.bottom="'Fill for only students with no attendance for date'"
                                        type="button"
                                        class="btn btn-bold btn-sm"
                                        :class="{
                                            'btn-success': fillMode === 'empty',
                                            'btn-clean': fillMode !== 'empty'
                                        }"
                                        @click.stop.prevent="setFillMode('empty')"
                                    >
                                        Empty
                                    </button>
                                </div>
                            </span>
                            <a
                                href="#"
                                class="btn btn-icon"
                                @click.stop.prevent="closeBulkAttendance"
                            >
                                <i class="la la-close" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body">
                    <div class="kt-section">
                        <div class="kt-section__info">
                            Set Attendance for all students on   <span class="font-weight-bold">{{ getDate }}</span>
                        </div>
                        <div class="kt-section__content kt-section__content--solid">
                            <button
                                type="button"
                                class="btn btn-outline-success mr-2"
                                @click.stop.prevent="save('Present')"
                            >
                                Present
                            </button>
                            <button
                                type="button"
                                class="btn btn-outline-danger mr-2"
                                @click.stop.prevent="save('Absent')"
                            >
                                Absent
                            </button>
                            <button
                                type="button"
                                class="btn btn-outline-warning mr-2"
                                @click.stop.prevent="save('Late')"
                            >
                                Late
                            </button>
                            <button
                                type="button"
                                class="btn btn-outline-primary mr-2"
                                @click.stop.prevent="save('Excused')"
                            >
                                Excused
                            </button>
                            <button
                                type="button"
                                class="btn btn-outline-secondary"
                                @click.stop.prevent="save(null)"
                            >
                                Clear
                            </button>
                        </div>
                    </div>
                    <div class="kt-separator kt-separator--space-sm  kt-separator--border-dashed" />
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import moment from 'moment';
import Types from '../store/Types';
import courseMixins from '../store/mixins/courseMixins';
import studentMixins from '../store/mixins/studentMixins';

export default {
    name: 'BulkAttendance',
    mixins: [courseMixins, studentMixins],
    props: {
        course: {
            type: Object,
            required: true,
        },
        students: {
            type: Array,
            required: true,
        },
        attendanceForDate: {
            type: Array,
            required: true,
        },
        bulkDate: {
            type: String,
            required: false,
            default: null,
        },
        bulkAttendanceVisible: {
            type: Boolean,
            required: true,
        },
        closeBulkAttendance: {
            type: Function,
            required: true,
        },
        incrementKey: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            fillMode: 'all',
        };
    },
    computed: {
        getDate() {
            if (this.bulkDate) return moment(this.bulkDate).format('YYYY-MM-DD');
            return moment(new Date()).format('YYYY-MM-DD');
        },
    },
    methods: {
        setFillMode(mode) {
            this.fillMode = mode;
        },
        save(attendanceState) {
            const {
                students, attendanceForDate, showError,
                incrementKey, course, getDate, fillMode,
            } = this;
            const attendanceDate = getDate;
            const attendanceData = [];
            students.forEach((student) => {
                if (!student) return;
                const { courseSectionId, courseSectionStudentId } = student;
                if (fillMode === 'empty' && attendanceState) {
                    const attendance = attendanceForDate.find((a) => a.courseSectionStudentId === courseSectionStudentId) || null;
                    if (attendance && attendance.attendanceState) return;
                }

                attendanceData.push({
                    courseSectionId,
                    courseSectionStudentId,
                    attendanceDate,
                    attendanceState,
                    attendanceComment: null,
                    deleted: !attendanceState,
                });
            });

            if (!attendanceData.length) return;
            this.$store.dispatch(Types.actions.SAVE_COURSE_SECTION_ATTENDANCE, {
                params: {
                    courseSectionId: course.courseSectionId,
                    courseSectionAttendance: attendanceData,
                },
                callback: (err, res) => {
                    if (err) return showError('Attendance could not be saved');
                    incrementKey();
                },
            });
        },
    },
};
</script>
