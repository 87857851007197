<template>
<span>
    <a
        id="course_attendance_period"
        title="Course attendance period"
        href="#"
        class="btn kt-subheader__btn-daterange pr-1"
        @click.stop.prevent="toggleMenuPopover"
    >
        <!-- <span class="kt-subheader__btn-daterange-title mr-2">
            {{ currentItem.startDate }}
        </span> -->
        <span
            v-if="currentItem"
            class="kt-subheader__btn-daterange-date"
        >
            {{ currentItem.label }}
        </span>
        <!-- <i class="flaticon2-calendar-1" /> -->
    </a>
    <b-popover
        triggers="focus"
        target="course_attendance_period"
        placement="bottom"
        :show.sync="show"
        custom-class="menu-popover mt-4"
    >
        <div
            v-if="currentItem"
            class="kt-widget4"
        >
            <div
                v-for="item in items"
                :key="`id_${item.id}`"
                class="kt-widget4__item"
            >
                <span
                    v-if="item.label == currentItem.label"
                    class="kt-widget4__icon"
                >
                    <i class="la la-check text-primary" />
                </span>
                <a
                    href="#"
                    :class="{ 'not-selected': item.label !== currentItem.label }"
                    class="kt-widget4__title kt-widget4__title--light"
                    @click.stop.prevent="setPeriod(item)"
                >
                    <span class="kt-menu__link-text"> {{ item.label }} </span>
                </a>
            </div>
        </div>
    </b-popover>

    <b-modal
        id="course-attendance-date-modal"
        ref="course-attendance-date-modal"
        size="lg"
        variant="primary"
        centered
    >
        <template #modal-header>
            <h4 class="modal-title">
                Custom Date Range
            </h4>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="closeModal()"
            />
        </template>
        <template #default>
            <div class="modal-body">
                <div class="row w-100 d-flex justify-content-around">
                    <div>
                        <div class="w-100">
                            <h5>Start Date</h5>
                        </div>
                        <b-calendar
                            id="start-datepicker"
                            v-model="modal.startDate"
                            class="date-selector pt-3 pb-4"
                            :min="minStartDate"
                            :max="maxStartDate"
                        />
                    </div>
                    <div>
                        <div class="w-100">
                            <h5>End Date</h5>
                        </div>
                        <b-calendar
                            id="end-datepicker"
                            v-model="modal.endDate"
                            class="date-selector pt-3"
                            :min="minEndDate"
                        />
                    </div>
                </div>
            </div>
        </template>
        <template #modal-footer>
            <div class="row w-100">
                <div class="col-6">
                    <button
                        type="button"
                        class="btn btn-danger"
                        @click="closeModal()"
                    >
                        Close
                    </button>
                </div>
                <div class="col-6">
                    <button
                        type="button"
                        class="btn btn-primary pull-right"
                        :disabled="isDisabled"
                        @click="setCustomPeriod()"
                    >
                        <i class="fa fa-save" />
                        Apply period
                    </button>
                </div>
            </div>
        </template>
    </b-modal>
</span>
</template>

<script lang="ts">
import Vue from 'vue';
import moment from 'moment';
import Types from '../../store/Types';

export default Vue.extend({
    name: 'PeriodDatePicker',
    components: {},
    data() {
        const todayName = moment().format('dddd'); // Monday ... Sunday
        const isWeekend = todayName === 'Sunday' || todayName === 'Saturday';

        const startOfCurrentWeek = moment().weekday(isWeekend ? 0 : 1); // sunday or monday
        const endOfCurrentWeek = moment().weekday(isWeekend ? 6 : 5); // sat or fri

        return {
            items: [
                {
                    label: 'This Week',
                    id: 'THIS_WEEK',
                    startDate: startOfCurrentWeek.format('YYYY-MM-DD'),
                    endDate: endOfCurrentWeek.format('YYYY-MM-DD'),
                },
                {
                    label: 'Last 2 weeks',
                    id: 'TWO_WEEKS',
                    startDate: startOfCurrentWeek.clone().add(-1, 'week').format('YYYY-MM-DD'),
                    endDate: endOfCurrentWeek.format('YYYY-MM-DD'),
                },
                {
                    label: 'This Month',
                    id: 'LAST_MONTH',
                    startDate: startOfCurrentWeek.clone().add(-4, 'weeks').format('YYYY-MM-DD'),
                    endDate: endOfCurrentWeek.format('YYYY-MM-DD'),
                },
            ],
            selectedItem: 'THIS_WEEK',
            show: false,
            modal: {
                startDate: startOfCurrentWeek.format('YYYY-MM-DD'),
                endDate: endOfCurrentWeek.format('YYYY-MM-DD'),
            },
        };
    },
    computed: {
        markingPeriods() {
            return this.$store.state.database.markingPeriods.filter((mp) => !mp.deleted);
        },
        currentItem() {
            const { label, items } = this;
            return items.find((item) => item.label == label) || null;
        },
        onlyCourseMeetings: {
            get() { return this.$store.state.database.attendance.onlyCourseMeetings; },
            set(onlyCourseMeetings) {
                this.$store.commit(Types.mutations.SET_ATTENDANCE_ONLY_COURSE_MEETINGS, onlyCourseMeetings);
            },
        },
        startDate: {
            get() { return this.$store.state.database.attendance.startDate; },
            set(startDate) {
                this.$store.commit(Types.mutations.SET_ATTENDANCE_START_DATE, startDate);
            },
        },
        endDate: {
            get() { return this.$store.state.database.attendance.endDate; },
            set(endDate) {
                this.$store.commit(Types.mutations.SET_ATTENDANCE_END_DATE, endDate);
            },
        },
        label: {
            get() { return this.$store.state.database.attendance.label; },
            set(label) {
                this.$store.commit(Types.mutations.SET_ATTENDANCE_LABEL, label);
            },
        },
        isDisabled() {
            const v = this;
            const startDate = moment(v.modal.startDate);
            const endDate = moment(v.modal.endDate);

            if (endDate <= startDate || endDate.diff(startDate, 'days') > 90) return true;

            return false;
        },
        minStartDate() {
            return moment(this.modal.endDate).add(-90, 'days').toDate();
        },
        maxStartDate() {
            return moment(this.modal.endDate).toDate();
        },
        minEndDate() {
            return moment(this.modal.startDate).toDate();
        },
        formattedStartDate() {
            const { startDate } = this;
            return moment(startDate, 'YYYY-MM-DD').format('ddd, MMM Do');
        },
        formattedEndDate() {
            const { endDate } = this;
            return moment(endDate, 'YYYY-MM-DD').format('ddd, MMM Do');
        },
        formattedPeriod() {
            return `${this.formattedStartDate} - ${this.formattedEndDate}`;
        },
    },
    mounted() {
        // select the last 2 weeks as the inital item
        const todayName = moment().format('dddd'); // Monday ... Sunday
        const isWeekend = todayName === 'Sunday' || todayName === 'Saturday';
        if (isWeekend) {
            this.onlyCourseMeetings = false;
        }
        const { items } = this;
        this.markingPeriods.forEach((mp) => {
            items.push({
                label: mp.markingPeriodName,
                id: mp.schoolTermMarkingPeriodId,
                startDate: mp.markingPeriodStart,
                endDate: mp.markingPeriodEnd,
            });
        });

        items.push({
            label: 'Custom Date Range',
            id: 'CUSTOM',
            startDate: '',
            endDate: '',
        });

        if (!this.currentItem) {
            this.setPeriod(items[0]);
        }

        if (!this.startDate || !this.endDate) {
            this.startDate = this.currentItem.startDate;
            this.endDate = this.currentItem.endDate;
            this.label = this.currentItem.label;
        }
        this.key += 1;
    },
    methods: {
        toggleMenuPopover() {
            this.show = !this.show;
        },
        setPeriod(item) {
            this.selectedItem = item.id;
            if (item.id !== 'CUSTOM') {
                this.startDate = item.startDate;
                this.endDate = item.endDate;
                this.label = item.label;
                this.show = false;
                return;
            }

            this.$refs['course-attendance-date-modal'].show();
            this.show = false;
        },
        setCustomPeriod() {
            this.startDate = moment(this.modal.startDate, 'YYYY-MM-DD');
            this.endDate = moment(this.modal.endDate, 'YYYY-MM-DD');
            this.$refs['course-attendance-date-modal'].hide();
        },
        closeModal() {
            this.$refs['course-attendance-date-modal'].hide();
        },
    },
});
</script>

<style scoped>
.dropdown-menu.show {
  width: 17rem;
  display: flex;
  flex-wrap: wrap;
}

.dropdown-item {
  min-width: 7rem;
  width: 7rem;
  cursor: pointer;
}

.not-selected {
  padding-left: 2.8rem;
}
</style>
